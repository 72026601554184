import {
  useGetAuthPermissionsQuery,
  useGetUsersMeQuery,
  useLazyGetTenantsByTenantIdQuery,
} from '../store/mgmtApi';
import { Link, Navigate } from 'react-router-dom';
import Heading from '../components/heading';
import { Menu, Transition } from '@headlessui/react';
import Avatar from 'react-avatar';
import React, { Fragment, useEffect, useState } from 'react';
import SimpleGridList, { SimpleGridListItem } from '../components/simpleGridList';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
const TenantSelection = () => {
  const { data: authData, isLoading, isSuccess, isError, error } = useGetAuthPermissionsQuery();
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    error: userDataError,
  } = useGetUsersMeQuery();

  const [tenants, setTenants] = useState<{ id: string; name?: string; role: string }[]>([]);

  const [triggerTenantLoad] = useLazyGetTenantsByTenantIdQuery();
  useEffect(() => {
    const fetchTenants = async () => {
      if (isSuccess && authData) {
        for (let i = 0; i < authData.permissions.length; i++) {
          const permission = authData.permissions[i];
          const { data: tenant } = await triggerTenantLoad(permission.tenantId);
          if (!tenant) {
            throw new Error(`Could not get tenant for id ${permission.tenantId}`);
          }
          setTenants((prevArray) => [
            ...prevArray,
            {
              name: tenant.name,
              id: tenant.id,
              role: permission.role,
            },
          ]);
        }
      }
    };

    fetchTenants();
  }, [authData]);

  if (isLoading) {
    return <p>Loading tenants...</p>;
  }
  if (isUserDataLoading) {
    return <p>Loading profile...</p>;
  }
  if (isError || !authData) {
    console.error(error);
    return <p>{`Error loading tenants: ${JSON.stringify(error)}`}</p>;
  }
  if (isUserDataError || !userData) {
    console.error(userDataError);
    return <p>{`Error loading profile: ${JSON.stringify(isUserDataError)}`}</p>;
  }

  if (tenants.length !== authData.permissions.length) {
    return <p>Loading tenants...</p>;
  }

  if (authData.permissions.length === 1) {
    return <Navigate to={`/t/${authData.permissions[0].tenantId}/dashboard`} />;
  }

  const gridItems: SimpleGridListItem[] = tenants.map(
    (tenant: { id: string; name?: string; role: string }) => {
      const item: SimpleGridListItem = {
        href: `/t/${tenant.id}/dashboard`,
        name: tenant.name ? tenant.name : tenant.id,
        subtitle: `Role: ${tenant.role}`,
      };
      return item;
    },
  );

  return (
    <>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
              alt="Workflow"
            />
          </div>
          <div className="mt-5 flex-grow flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1"></nav>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <div className="flex-1 px-4 flex justify-between">
            <div className="ml-4 flex items-center md:ml-6">
              <Heading text={'Welcome back'} size={'h5'} />
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button
                    className="max-w-xs bg-white flex items-center text-sm rounded-full
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Open user menu</span>
                    <Avatar round size={'40'} name={userData.email} email={userData.email} />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="origin-top-right absolute right-0 mt-2 w-48
                    rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <Menu.Item>
                      {() => (
                        <>
                          <p className={'text-xs text-center line-clamp-2 break-all'}>
                            {userData.email}
                          </p>
                          <hr />
                        </>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={'/signout'}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700',
                          )}
                        >
                          Sign Out
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <SimpleGridList title={'My Tenants'} items={gridItems} />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default TenantSelection;
