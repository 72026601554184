/* This example requires Tailwind CSS v2.0+ */
import Badge, { BadgeColor } from '../badge';
import React from 'react';

export interface TableHeader {
  content: TableItem;
  expectedValueLength: number;
}

export interface MainAction {
  title: string;
  actionFunc: () => void;
}

export type TableItem = React.JSX.Element | string;
export type TableRow = TableItem[];

export interface TableProps {
  title: string;
  subTitle: string;
  headers: TableHeader[];
  items: TableRow[] | null;
  mainAction?: MainAction;
  className?: string;
}
export default function Table(props: TableProps) {
  return (
    <div className={`px-4 sm:px-6 lg:px-8 ${props.className ? props.className : ''}`}>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">{props.title}</h1>
          <p className="mt-2 text-sm text-gray-700">{props.subTitle}</p>
        </div>
        {props.mainAction && (
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => {
                if (props.mainAction) {
                  props.mainAction.actionFunc();
                }
              }}
              type="button"
              className={
                'inline-flex items-center justify-center rounded-md border ' +
                'border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white ' +
                'shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 ' +
                'focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
              }
            >
              {props.mainAction.title}
            </button>
          </div>
        )}
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {props.headers.length > 0 && (
                <table className="divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {props.headers.map((h, idx) => {
                        return (
                          <th
                            key={`header-${idx}`}
                            scope="col"
                            className={
                              `px-3 py-3.5 text-left text-sm ` + `font-semibold text-gray-900`
                            }
                          >
                            {h.content}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {props.items == null && (
                      <>
                        {[1, 2].map((idx) => (
                          <tr
                            key={`l-tr-${idx}`}
                            className={idx % 2 === 0 ? undefined : 'bg-gray-50'}
                          >
                            {props.headers.map((h, hidx) => (
                              <td
                                key={`l-td-${idx}-${hidx}`}
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                              >
                                <Badge
                                  color={BadgeColor.Grey}
                                  expectedChars={h.expectedValueLength}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    )}
                    {props.items != null &&
                      props.items.map((row, rowIdx) => (
                        <tr
                          key={`row-${rowIdx}`}
                          className={rowIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                        >
                          {row.map(
                            (col, cidx) => (
                              <td
                                key={`td-${rowIdx}-${cidx}`}
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                              >
                                {col}
                              </td>
                            ),
                            /*     } else {
                              return (
                              <td
                                  className={
                                      'relative whitespace-nowrap py-4 pl-3 pr-4 ' +
                                      'text-right text-sm font-medium sm:pr-6'
                                  }
                              >
                                <a
                                    href="#"
                                    className="text-indigo-600 hover:text-indigo-900"
                                    onClick={() => {
                                      col.actionFunc(row);
                                    }}
                                >
                                  {col.title}
                                </a>
                              </td>
                              )
                            }*/
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
