import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
  'auth',
  'permissions',
  'users',
  'tenants',
  'portals',
  'invites',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postAuth: build.mutation<PostAuthApiResponse, PostAuthApiArg>({
        query: (queryArg) => ({ url: `/auth/`, method: 'POST', body: queryArg }),
        invalidatesTags: ['auth'],
      }),
      postAuthLogout: build.mutation<PostAuthLogoutApiResponse, PostAuthLogoutApiArg>({
        query: () => ({ url: `/auth/logout`, method: 'POST' }),
        invalidatesTags: ['auth'],
      }),
      getAuthPermissions: build.query<GetAuthPermissionsApiResponse, GetAuthPermissionsApiArg>({
        query: () => ({ url: `/auth/permissions` }),
        providesTags: ['permissions'],
      }),
      getAuthPermissionsByTenantByTenantId: build.query<
        GetAuthPermissionsByTenantByTenantIdApiResponse,
        GetAuthPermissionsByTenantByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/auth/permissions/byTenant/${queryArg}` }),
        providesTags: ['permissions'],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ['users'],
      }),
      getUsersByUserId: build.query<GetUsersByUserIdApiResponse, GetUsersByUserIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg}` }),
        providesTags: ['users'],
      }),
      getTenantsByTenantId: build.query<
        GetTenantsByTenantIdApiResponse,
        GetTenantsByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/tenants/${queryArg}` }),
        providesTags: ['tenants'],
      }),
      patchTenantsByTenantId: build.mutation<
        PatchTenantsByTenantIdApiResponse,
        PatchTenantsByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tenants/${queryArg.tenantId}`,
          method: 'PATCH',
          body: queryArg.patchTenantRequest,
        }),
        invalidatesTags: ['tenants'],
      }),
      getPortalsByTenantId: build.query<
        GetPortalsByTenantIdApiResponse,
        GetPortalsByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/portals/${queryArg}` }),
        providesTags: ['portals'],
      }),
      postInvitesByTenantId: build.mutation<
        PostInvitesByTenantIdApiResponse,
        PostInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/invites/${queryArg.tenantId}`,
          method: 'POST',
          body: queryArg.createInviteRequest,
        }),
        invalidatesTags: ['invites'],
      }),
      deleteInvitesByTenantId: build.mutation<
        DeleteInvitesByTenantIdApiResponse,
        DeleteInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({
          url: `/invites/${queryArg.tenantId}`,
          method: 'DELETE',
          body: queryArg.deleteInviteRequest,
        }),
        invalidatesTags: ['invites'],
      }),
      getInvitesByTenantId: build.query<
        GetInvitesByTenantIdApiResponse,
        GetInvitesByTenantIdApiArg
      >({
        query: (queryArg) => ({ url: `/invites/${queryArg}` }),
        providesTags: ['invites'],
      }),
      postInvitesAccept: build.mutation<PostInvitesAcceptApiResponse, PostInvitesAcceptApiArg>({
        query: (queryArg) => ({
          url: `/invites/accept`,
          method: 'POST',
          body: queryArg.genericPostRequest,
          params: { token: queryArg.token },
        }),
        invalidatesTags: ['invites'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mgmtApi };
export type PostAuthApiResponse =
  /** status 200 Permissions and session cookie */ PermissionsResponse;
export type PostAuthApiArg = TokenBodyRequest;
export type PostAuthLogoutApiResponse = /** status 200 Logout response */ LogoutResponse;
export type PostAuthLogoutApiArg = void;
export type GetAuthPermissionsApiResponse = /** status 200 Permissions */ PermissionsResponse;
export type GetAuthPermissionsApiArg = void;
export type GetAuthPermissionsByTenantByTenantIdApiResponse =
  /** status 200 TenantPermissions */ TenantPermissionsResponse;
export type GetAuthPermissionsByTenantByTenantIdApiArg = /** tenant id */ string;
export type GetUsersMeApiResponse = /** status 200 Found user */ UserResponse;
export type GetUsersMeApiArg = void;
export type GetUsersByUserIdApiResponse = /** status 200 Found user */ UserResponse;
export type GetUsersByUserIdApiArg = /** userId */ string;
export type GetTenantsByTenantIdApiResponse = /** status 200 Found tenant */ TenantResponse;
export type GetTenantsByTenantIdApiArg = /** tenantId */ string;
export type PatchTenantsByTenantIdApiResponse = /** status 200 Updated tenant */ GenericResponse;
export type PatchTenantsByTenantIdApiArg = {
  /** tenant id */
  tenantId: string;
  patchTenantRequest: PatchTenantRequest;
};
export type GetPortalsByTenantIdApiResponse = /** status 200 Found portal */ PortalResponse;
export type GetPortalsByTenantIdApiArg = /** tenantId */ string;
export type PostInvitesByTenantIdApiResponse = /** status 200 Created invite */ GenericResponse;
export type PostInvitesByTenantIdApiArg = {
  /** tenantId */
  tenantId: string;
  createInviteRequest: CreateInviteRequest;
};
export type DeleteInvitesByTenantIdApiResponse = /** status 200 Deleted invite */ GenericResponse;
export type DeleteInvitesByTenantIdApiArg = {
  /** tenantId */
  tenantId: string;
  deleteInviteRequest: DeleteInviteRequest;
};
export type GetInvitesByTenantIdApiResponse = /** status 200 Found invites */ InvitesResponse;
export type GetInvitesByTenantIdApiArg = /** tenantId */ string;
export type PostInvitesAcceptApiResponse = /** status 200 Accepted invite */ GenericResponse;
export type PostInvitesAcceptApiArg = {
  token: string;
  genericPostRequest: GenericPostRequest;
};
export type PermissionsResponse = {
  permissions: {
    tenantId: string;
    role: string;
    userId: string;
  }[];
};
export type TokenBodyRequest = {
  idToken: string;
};
export type LogoutResponse = {
  success: boolean;
};
export type TenantPermissionsResponse = {
  permissions: {
    tenantId: string;
    role: string;
    userId: string;
    email: string;
  }[];
};
export type UserResponse = {
  id: string;
  email: string;
};
export type TenantResponse = {
  id: string;
  name?: string;
};
export type GenericResponse = {
  successful?: boolean;
};
export type PatchTenantRequest = {
  id?: string;
  name?: string;
};
export type PortalResponse = {
  host: string;
  customHost?: string;
};
export type CreateInviteRequest = {
  email: string;
};
export type DeleteInviteRequest = {
  id: string;
};
export type InvitesResponse = {
  invites?: {
    email: string;
    status: 'CREATED' | 'EXPIRED';
    created: string;
    id: string;
  }[];
};
export type GenericPostRequest = object;
export const {
  usePostAuthMutation,
  usePostAuthLogoutMutation,
  useGetAuthPermissionsQuery,
  useLazyGetAuthPermissionsQuery,
  useGetAuthPermissionsByTenantByTenantIdQuery,
  useLazyGetAuthPermissionsByTenantByTenantIdQuery,
  useGetUsersMeQuery,
  useLazyGetUsersMeQuery,
  useGetUsersByUserIdQuery,
  useLazyGetUsersByUserIdQuery,
  useGetTenantsByTenantIdQuery,
  useLazyGetTenantsByTenantIdQuery,
  usePatchTenantsByTenantIdMutation,
  useGetPortalsByTenantIdQuery,
  useLazyGetPortalsByTenantIdQuery,
  usePostInvitesByTenantIdMutation,
  useDeleteInvitesByTenantIdMutation,
  useGetInvitesByTenantIdQuery,
  useLazyGetInvitesByTenantIdQuery,
  usePostInvitesAcceptMutation,
} = injectedRtkApi;
