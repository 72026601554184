import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePostAuthMutation } from '../store/mgmtApi';
import { useEffect, useRef } from 'react';

export const WaitForSetupPage = () => {
  const [exchangeToken, { data: exchangeTokenData, error: exchangeTokenError }] =
    usePostAuthMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idToken = searchParams.get('token');

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!idToken) {
      return;
    }
    intervalRef.current = setInterval(() => {
      exchangeToken({ idToken });
    }, 1000);
    return () => clearInterval(intervalRef.current!);
  }, [idToken]);

  useEffect(() => {
    if (exchangeTokenData && exchangeTokenData.permissions.length > 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      navigate('/');
    }
  }, [exchangeTokenData, navigate]);

  if (exchangeTokenError) {
    console.error(exchangeTokenError);
    return <p>Setup failed</p>;
  }

  return <p>Wait for setup...</p>;
};
