import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import TenantSelection from './tenantSelection/tenantSelection';
import React from 'react';
import TenantManagement from './tenantManagement/TenantManagement';
import './app.css';
import { JoinPage } from './join';
import { LoginPage } from '@api-one-app/apione.frontend.login';
import LoginGuard from '@api-one-app/apione.frontend.login/guard';
import { usePostAuthMutation } from './store/mgmtApi';
import { SignOutPage } from './signout';
import { WaitForSetupPage } from './waitForSetup';

export const App = () => {
  const guardProps = {
    loginRoute: '/login',
    cookieName: 'a1auth',
  };

  const [exchangeToken, { data: exchangeTokenData, error: exchangeTokenError }] =
    usePostAuthMutation();

  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tenantSelection" />} />
      <Route
        path="/tenantSelection"
        element={
          <LoginGuard {...guardProps}>
            <TenantSelection />
          </LoginGuard>
        }
      />

      <Route
        path="/t/:tenant/*"
        element={
          <LoginGuard {...guardProps}>
            <TenantManagement />
          </LoginGuard>
        }
      />
      <Route
        path="/join"
        element={
          <LoginGuard {...guardProps}>
            <JoinPage />
          </LoginGuard>
        }
      />
      <Route
        path="/setup"
        element={
          <LoginGuard {...guardProps}>
            <WaitForSetupPage />
          </LoginGuard>
        }
      />

      <Route path="/signout" element={<SignOutPage />} />

      <Route
        path="/login"
        element={
          <LoginPage
            cognitoClientId="pg4g8an8a2bjodjnaru0lpg8r"
            cognitoRegion="eu-west-1"
            loggedInAction={async (idToken: string) => {
              await exchangeToken({
                idToken: idToken,
              });
              if (exchangeTokenError) {
                console.warn(exchangeTokenError);
                alert(exchangeTokenError);
              } else if (exchangeTokenData && exchangeTokenData.permissions.length > 0) {
                navigate('/');
              } else {
                navigate(`/setup?token=${idToken}`);
              }
            }}
          />
        }
      />
    </Routes>
  );
};
