import React, { useState } from 'react';
import {
  useDeleteInvitesByTenantIdMutation,
  useGetAuthPermissionsByTenantByTenantIdQuery,
  useGetInvitesByTenantIdQuery,
  usePostInvitesByTenantIdMutation,
} from '../../store/mgmtApi';
import SubPageProps from '../props';
import Table, { MainAction, TableHeader, TableRow } from '../../components/table';
import Modal from '../../components/modal';
import Input from '../../components/input';
import { TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import validator from 'validator';
import Notification, { NotificationStatus } from '../../components/notification';

const usersTableHeaders: TableHeader[] = [
  { content: 'Email', expectedValueLength: 25 },
  { content: 'Role', expectedValueLength: 6 },
];

const adminUsersTableHeaders = [
  ...usersTableHeaders,
  { content: 'Action', expectedValueLength: 7 },
];

const invitesTableHeaders: TableHeader[] = [
  { content: 'Email', expectedValueLength: 25 },
  { content: 'Invited', expectedValueLength: 10 },
  { content: 'Status', expectedValueLength: 7 },
];
const adminInvitesTableHeaders = [
  ...invitesTableHeaders,
  { content: 'Action', expectedValueLength: 7 },
];

const INVITE_STATUS = {
  CREATED: 'Pending',
  EXPIRED: 'Expired',
};

const getUserEditAction = (userMail: string, userRole: string): React.JSX.Element => {
  return (
    <button
      className="text-indigo-600 hover:text-indigo-900"
      onClick={() => {
        console.log(`edit user ${userMail} with role ${userRole}`);
      }}
    >
      Edit
    </button>
  );
};

const MembersSettings = (props: SubPageProps) => {
  const isAdmin = props.user.role == 'admin';

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteMemberMail, setInviteMemberMail] = useState('');
  const [showInviteNotification, setShowInviteNotification] = useState(false);

  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const [revokeEmail, setRevokeEmail] = useState('');
  const [revokeId, setRevokeId] = useState('');

  const [createInvite, { isSuccess: createInviteSuccess }] = usePostInvitesByTenantIdMutation();

  const [deleteInvite, { isLoading: deleteInviteLoading }] = useDeleteInvitesByTenantIdMutation();

  const {
    data: invitesData,
    isLoading: invitesLoading,
    isError: invitesLoadHasError,
  } = useGetInvitesByTenantIdQuery(props.tenant.id);

  const {
    data: userRoles,
    isLoading: usersLoading,
    isError: usersLoadHasError,
    error: usersLoadError,
  } = useGetAuthPermissionsByTenantByTenantIdQuery(props.tenant.id);

  if (usersLoadHasError) {
    return <p>{`loading members failed: ${usersLoadError}`}</p>;
  }
  if (invitesLoadHasError) {
    return <p>{`loading members failed: ${invitesLoadHasError}`}</p>;
  }

  let userItems: TableRow[] | null = null;
  if (userRoles != undefined && !usersLoading) {
    userRoles.permissions.forEach((userRole) => {
      const columns: TableRow = [userRole.email, userRole.role];
      if (isAdmin) {
        columns.push(getUserEditAction(userRole.email, userRole.role));
      }
      if (userItems == null) {
        userItems = [];
      }
      userItems.push(columns);
    });
  }

  let inviteItems: TableRow[] | null = null;

  if (invitesData != undefined && invitesData.invites && !invitesLoading) {
    inviteItems = [];
    const sortedInvites = [...invitesData.invites].sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    );
    sortedInvites.forEach((invite) => {
      const columns: TableRow = [
        invite.status == 'EXPIRED' ? <s>{invite.email}</s> : invite.email,
        invite.created.substring(0, 10),
        INVITE_STATUS[invite.status],
      ];
      if (isAdmin) {
        columns.push(
          <button
            className="text-red-500 hover:text-orange-700 inline-flex items-center"
            onClick={() => {
              setRevokeId(invite.id);
              setRevokeEmail(invite.email);
              setRevokeModalOpen(true);
            }}
          >
            <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Revoke
          </button>,
        );
      }
      if (inviteItems == null) {
        inviteItems = [];
      }
      inviteItems.push(columns);
    });
  }

  const inviteAction: MainAction = {
    title: 'Invite Member',
    actionFunc: () => {
      setInviteModalOpen(true);
    },
  };

  const sendInvite = () => {
    createInvite({
      tenantId: props.tenant.id,
      createInviteRequest: {
        email: inviteMemberMail,
      },
    })
      .catch((err) => {
        alert('error creating invitation: ' + err);
      })
      .finally(() => {
        setInviteMemberMail('');
        setInviteModalOpen(false);
        setShowInviteNotification(true);
      });
  };

  const revokeInvite = () => {
    deleteInvite({
      tenantId: props.tenant.id,
      deleteInviteRequest: {
        id: revokeId,
      },
    })
      .catch((err) => {
        alert('error deleting invitation: ' + err);
      })
      .finally(() => {
        setRevokeEmail('');
        setRevokeId('');
        setRevokeModalOpen(false);
      });
  };

  const noInvites = inviteItems != null && inviteItems.length == 0;

  return (
    <div className="pl-5">
      <Notification
        show={showInviteNotification}
        setShow={setShowInviteNotification}
        title={createInviteSuccess ? 'Invite sent' : 'Invite could not be sent'}
        text={createInviteSuccess ? 'Details sent via E-Mail' : 'Try again later'}
        status={createInviteSuccess ? NotificationStatus.Success : NotificationStatus.Error}
        time={createInviteSuccess ? 2000 : 20000}
      />
      <Modal
        isOpen={inviteModalOpen}
        setOpen={setInviteModalOpen}
        title={'Invite Member'}
        cancelText={'Cancel'}
        cancelAction={() => {
          setInviteMemberMail('');
          setInviteModalOpen(false);
        }}
        nextDisabled={
          inviteMemberMail == '' || !validator.isEmail(inviteMemberMail) || invitesLoading
        }
        nextText={'Invite'}
        icon={UserPlusIcon}
        nextAction={sendInvite}
        closeOnNext={false}
        body={
          <Input
            disabled={invitesLoading}
            htmlId={'invitedMail'}
            name={'invitedMail'}
            value={inviteMemberMail}
            onChange={(value) => {
              setInviteMemberMail(value);
            }}
            placeholder={'colleague@company.com'}
            title={'Enter email address'}
          />
        }
      />

      <Modal
        isOpen={revokeModalOpen}
        setOpen={setRevokeModalOpen}
        title={'Revoke Invite'}
        cancelText={'Cancel'}
        cancelAction={() => {
          setRevokeId('');
          setRevokeEmail('');
          setRevokeModalOpen(false);
        }}
        nextDisabled={deleteInviteLoading}
        nextText={'Revoke'}
        icon={TrashIcon}
        nextAction={revokeInvite}
        closeOnNext={false}
        body={<p>Revoke invite for {revokeEmail}?</p>}
      />

      <Table
        title={'Members'}
        subTitle={'Overall of all your team members'}
        headers={isAdmin ? adminUsersTableHeaders : usersTableHeaders}
        items={userItems}
        mainAction={isAdmin ? inviteAction : undefined}
      />

      <Table
        className={'margin-t3'}
        title={'Invites'}
        subTitle={noInvites ? 'No pending invitations' : 'Pending invitations'}
        headers={noInvites ? [] : isAdmin ? adminInvitesTableHeaders : invitesTableHeaders}
        items={inviteItems}
      />
    </div>
  );
};

export default MembersSettings;
