import { usePostAuthLogoutMutation } from '../store/mgmtApi';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const SignOutPage = () => {
  const [signOutAction, { data, error }] = usePostAuthLogoutMutation();

  useEffect(() => {
    signOutAction();
  }, []);

  if (data) {
    return <Navigate to="/" />;
  }
  return <p>Logout...</p>;
};
