import React from 'react';
import { useGetPortalsByTenantIdQuery } from '../../store/mgmtApi';
import SubPageProps from '../props';
import { ArrowTopRightOnSquareIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import Table from '../../components/table';

const link = (url: string) => (
  <a
    target="_blank"
    href={"https://" + url}
    className={'underline flex items-center text-gray-500 hover:text-gray-900'}
  >
    {url} <ArrowTopRightOnSquareIcon className="ml-2" height={15} />
  </a>
);

const PortalGeneralSettings = (props: SubPageProps) => {
  const {
    data: portal,
    isError: portalLoadError,
    isLoading: portalIsLoading,
  } = useGetPortalsByTenantIdQuery(props.tenant.id);
  if (portalLoadError) {
    return <h3>Error loading portal</h3>;
  }

  const editButton = (
    <button type="button">
      <Cog6ToothIcon height={20} />
    </button>
  );

  let items = null;
  if (portal) {
    items = [
      ['Default URL', link(portal.host)],
      ['Custom URL', portal.customHost ? link(portal.customHost) : 'Not set'],
    ];
  }

  return (
    <>
      <Table
        headers={[
          { content: 'Portal Info', expectedValueLength: 10 },
          { content: '', expectedValueLength: 70 },
        ]}
        items={items}
        subTitle={''}
        title={''}
      />
    </>
  );
};

export default PortalGeneralSettings;
